import { Image } from 'react-bootstrap';

function IssuesHero() {
  return (
      <div>
        <Image src="IssuesHero.jpg" width="100%" height="100%" rounded />
      </div>
  );
}

export default IssuesHero;
import React from 'react';
import { ButtonGroup, Button, Container } from 'react-bootstrap';
import { FaFacebookF, FaTwitter, FaInstagram } from 'react-icons/fa';
import { Link } from 'react-router-dom';

interface SocialButtonGroupProps {
    showLabel:boolean;
}

const SocialButtonGroup:React.FC<SocialButtonGroupProps> = ({showLabel}) => {

    return (
        <Container className="social-group">
            {showLabel && <h4>Follow Us</h4>}
            <ButtonGroup aria-label="Social Media">
            <Link to="https://facebook.com/Guadalupereyes4AD41/about/" target="_blank">
                <Button className="social-button">
                    <FaFacebookF/>
                </Button>
            </Link>
            &nbsp;
            <Link to="https://twitter.com/GReyes4AD41/" target="_blank">
                <Button className="social-button"><FaTwitter /></Button>
            </Link>
            &nbsp;
            <Link to="https://instagram.com/greyes4assemblydistrict41/" target="_blank">
                <Button className="social-button"><FaInstagram /></Button>
            </Link>
            </ButtonGroup>
        </Container>
    );
};

export default SocialButtonGroup;

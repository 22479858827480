import React from 'react';
import { Accordion, Card } from 'react-bootstrap';

const Platform:React.FC = () => {
    return (
        <Card>
            <Card.Img variant="top" src="REYES_BANNER.jpg" />
            <Card.Body>
            <Card.Text>
                <Accordion defaultActiveKey="0" flush alwaysOpen>
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><b><span className="schibsted-grotesk-600">EDUCATION</span></b></Accordion.Header>
                        <Accordion.Body>
                        <h3 className="platform-header schibsted-grotesk-600">The Children are our Future</h3>
                        <hr/>
                        <ul className="platform-item schibsted-grotesk-400">
                            <li><b>Parental choice</b>: I believe that parents should have the right to choose the best education for their children. This includes the right to choose between public, private, and charter schools.</li>
                            <li><b>School choice</b>: I support expanding school choice options for families, such as vouchers and tax credits.</li>
                            <li><b>Teacher quality</b>: I believe that the quality of teachers is the most important factor in student success. I support policies that will attract and retain the best teachers in the classroom.</li>
                            <li><b>Curriculum</b>: I believe that parents should have a say in what their children are taught in school. I oppose the teaching of critical race theory and other divisive concepts in our schools.</li>
                            <li><b>School safety</b>: I believe that all schools should be safe and secure. I support policies that will keep our children safe from gun violence and other threats.</li> 
                        </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                        <Accordion.Header><b><span className="schibsted-grotesk-600">LOCAL SAFETY</span></b></Accordion.Header>
                        <Accordion.Body>
                        <h3 className="platform-header schibsted-grotesk-600">Our citizens' safety ought to be our top priority</h3>
                        <hr/>
                        <ul className="platform-item schibsted-grotesk-400">
                            <li><b>Providing more resources to law enforcement</b>: I believe that law enforcement is the first line of defense against crime, and we support providing them with the resources they need to do their jobs effectively. This includes increasing funding for police departments, training, and equipment.</li>
                            <li><b>Supporting community policing</b>: I believe that community policing is an effective way to build trust between law enforcement and the communities they serve. I support policies that will promote community policing and encourage officers to get to know the people they are sworn to protect.</li>
                            <li><b>Enforcing the Law</b>: I believe that the law should be enforced fairly and consistently, regardless of the individual's race, religion, or social status. I support policies that will hold criminals accountable for their actions and protect the innocent.</li>
                            <li><b>Preventing Crime</b>: I believe that the best way to reduce crime is to prevent it from happening in the first place. I believe that we need to invest in crime prevention programs to keep our communities safe. This includes programs that provide after-school activities for kids, job training for adults, and other programs that help people stay out of trouble.</li>
                        </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                        <Accordion.Header><b><span className="schibsted-grotesk-600">ECONOMY</span></b></Accordion.Header>
                        <Accordion.Body>
                        <h3 className="platform-header schibsted-grotesk-600">The following fundamental ideas serve as the foundation of my economic agenda</h3>
                        <hr/>
                        <ul className="platform-item schibsted-grotesk-400">
                            <li><b>Promoting a Pro-Business Environment</b>: We must reduce burdensome regulations, streamline the permitting process, and lower taxes to create a conducive environment for businesses to invest, grow, and create jobs.</li>
                            <li><b>Investing in Infrastructure</b>: Strong infrastructure is the backbone of a thriving economy. We must invest in modernizing our transportation systems, expanding broadband access, and upgrading our energy infrastructure.</li>
                            <li><b>Enhancing Workforce Development</b>: We must equip our workforce with the skills and training they need to succeed in the 21st-century economy. This includes expanding access to quality education, fostering partnerships with businesses for job training, and promoting lifelong learning opportunities.</li>
                            <li><b>Fostering Innovation</b>: Nevada is home to a vibrant entrepreneurial spirit. We must nurture this spirit by supporting innovation hubs, investing in research and development, and encouraging collaboration between businesses, universities, and research institutions.</li>
                        </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="3">
                        <Accordion.Header><b><span className="schibsted-grotesk-600">LIMITED GOVERNMENT</span></b></Accordion.Header>
                        <Accordion.Body>
                            <h3 className="platform-header schibsted-grotesk-600">Government should be limited in its scope and power</h3>
                            <hr/>
                            <span className="platform-item schibsted-grotesk-400">It should not be in the business of micromanaging our lives or picking winners and losers in the economy. Instead, government's role should be to protect our rights, provide for our national defense, and ensure a level playing field for all Nevadans, this incudes individual liberty and economic freedom.</span>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="4">
                        <Accordion.Header><b><span className="schibsted-grotesk-600">LOVE LIFE</span></b></Accordion.Header>
                        <Accordion.Body>
                            <h3 className="platform-header schibsted-grotesk-600">Life Begins at Conception</h3>
                            <hr/>
                            <ul className="platform-item schibsted-grotesk-400">
                                <li>I believe that the family is the foundation of society and that the government should support policies that strengthen families. I believe that all human life is sacred and that we should protect the lives of the unborn, the elderly, and the disabled.</li>
                                <li>I support legislation to make clear that the Fourteenth Amendment's protections apply to unborn children.</li>
                                <li>I oppose the use of public funds to subsidize or promote abortion. This includes opposing federal funding for Planned Parenthood and other abortion providers.</li>
                            </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="5">
                        <Accordion.Header><b><span className="schibsted-grotesk-600">PROTECTING OUR FREEDOM-2ND AMENDMENT</span></b></Accordion.Header>
                        <Accordion.Body>
                        <h3 className="platform-header schibsted-grotesk-600">The Second Amendment of the United States Constitution guarantees the right to keep and bear arms, a fundamental freedom enshrined by our Founding Fathers. As a Republican, I stand firmly in defense of this vital right, recognizing it as an individual liberty and a cornerstone of a secure nation</h3>
                        <hr/>
                        <ul className="platform-item schibsted-grotesk-400">
                            <li>I reject attempts to chip away at this right through vague interpretations or gun control measures that infringe upon law-abiding citizens.</li>
                            <li>The right to own and carry a firearm for self-defense will be vigorously protected. I support national reciprocity for concealed carry permits, allowing responsible gun owners to exercise their rights regardless of state lines.</li>
                            <li>Focus on mental health, not gun control, as the key to preventing violence. I support increased access to mental health services and resources while respecting individual privacy.</li>
                            <li>I Oppose gun bans and confiscation. These measures only disarm law-abiding citizens, leaving them vulnerable to criminals who will continue to acquire firearms illegally.</li>
                            <li>Promote responsible gun ownership through education and training programs. I encourage gun safety education in schools and communities to ensure the safe and responsible handling of firearms.</li>
                            <li>Uphold the rights of law-abiding gun manufacturers and sellers. I oppose burdensome regulations and lawsuits that stifle the firearms industry and limit access to firearms for responsible citizens.</li>
                        </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="6">
                        <Accordion.Header><b><span className="schibsted-grotesk-600">IMMIGRATION</span></b></Accordion.Header>
                        <Accordion.Body>
                        <h3 className="platform-header schibsted-grotesk-600">My Platform on immigration is centered around several key principles</h3>
                        <hr/>
                        <ul className="platform-item schibsted-grotesk-400">
                            <li><b>Securing the border</b>: I believe that the U.S border should be fully secured to prevent illegal immigration and drug/human trafficking. I support increasing funding for border security measures, such as building a wall, hiring more border patrol agents, and using technology to improve surveillance.</li>
                            <li><b>Enforcing immigration laws</b>: Existing immigration laws should be enforced more strictly, including deporting undocumented immigrants who are convicted of crimes. </li>
                            <li><b>Reducing legal immigration</b>: I believe that legal immigration levels should be reduced, arguing that it depresses wages for American workers and strains social services. I support measures such as reducing the number of visas issued each year.</li>
                            <li><b>Merit-based immigration</b>: I support a shift towards a merit-based immigration system, which would give preference to immigrants with high levels of education and skills. We want to attract the best and brightest talent to the United States and boost the economy.</li>
                        </ul>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Card.Text>
            </Card.Body>
        </Card>
    );
}

export default Platform;
import { Container } from "react-bootstrap";
import Platform from "../components/Platform";
import Footer from "../components/Footer";
import IssuesHero from "../components/hero/Issues";

const IssuesPage = () => {
    return (
        <Container fluid>
            <IssuesHero />
            <br/>
            <Platform />
            <br/>
            <Footer />
        </Container>
    );
}

export default IssuesPage;
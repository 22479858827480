import { Container } from "react-bootstrap";
import Footer from "../components/Footer";
import AboutHero from "../components/hero/About";


const AboutPage = () => {
    return (
        <Container fluid>
            <AboutHero />
            <div className="about-text schibsted-grotesk-400">
            
                <p>I'm Guadalupe Reyes. Hello. This community is knit together with my story. I was raised in a traditional Mexican home where my father marched with Cesar Chavez and my mother cleaned hotel rooms, teaching me the importance of hard labor and honesty. I raise my son with these same ideals and live by them.</p>
                <br/>
                <h1>"I raise my son with these same ideals and live by them."</h1>
                <br/>
            
                <p>Along with my husband and our teenage son, I have been proud to call this location home for more than nine years. But I've noticed some shadows moving in lately. Future prospects for our kids are at risk due to the disintegration of our educational system. I was unable to ignore it as a mother. Because I couldn't take a chance on the safety and quality of education, I homeschooled my son. However, it isn't a sustainable solution. We are worthy of better.</p>
                <br/>
                <h1>"We are worthy of better."</h1>
                <br/>
            
                <p>Despite being expected to listen, our local officials have not done so. Families feel unsafe because violent crime and homelessness are on the rise. It can no longer be tolerated!</p>
                <br/>
                <h1>"It can no longer be tolerated!"</h1>
                <br/>
            
                <p>I am a candidate for Assembly District 41 because of this. The "establishment" is not me. I work for you and am also your neighbor, a businesswoman, and a mother. I've taken on obstacles head-on, managing the intricacies of childrearing, operating a business, and comprehending the requirements of our community. I have the know-how, the perseverance, and the steadfast dedication to see our town prosper. No more pretentious statements or "business as usual." I'll stand up for high-quality education, secure neighborhoods, reasonably priced healthcare, and equitable access to the economy.</p>
                <br/>
                <h1>"I'll stand up for high-quality education, secure neighborhoods, reasonably priced healthcare, and equitable access to the economy."</h1>
                <br/>

                <p>I'm not the focus of this campaign. The future we deserve, along with you and your family, is what matters. All opinions count, and all issues should be heard. Embark with me as we jointly construct a more promising future.</p>
                <br/>
                <h1>"Embark with me as we jointly construct a more promising future."</h1>
                <br/>

            </div>
            <Footer />
        </Container>
    );
}

export default AboutPage;
import React, { useState } from 'react';
import { Button, Nav } from 'react-bootstrap';


const Contribute:React.FC = () => { 
    const [isContribHovered, setIsContribHovered] = useState(false);
    const [isVolHovered, setIsVolHovered] = useState(false);
    
    const buttonStyle = {
        backgroundColor: '#cc3333',
        fontFamily: 'Schibsted Grotesk',
        fontWeight: 900,
        borderRadius: 0,
        borderColor: '#cc3333',
        letterSpacing: '2px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.3)',
        transition: 'background-color 0.3s ease', // Smooth transition on hover
    };
    
    const buttonHoverStyle = {
        borderColor: '#24337a',
        backgroundColor: '#24337a', // Change background color on hover
    };

    return (
        <div className="d-grid gap-2">
            <Button 
                variant="primary" 
                style={{ ...buttonStyle, ...(isContribHovered && buttonHoverStyle) }}
                size="lg"
                onMouseEnter={() => setIsContribHovered(true)}
                onMouseLeave={() => setIsContribHovered(false)}
                >
                <Nav.Link target="_blank" href="https://buy.stripe.com/cN2g2n9Lz2NG41ObIJ">CONTRIBUTE</Nav.Link>
            </Button>
            <Button 
                variant="secondary" 
                style={{ ...buttonStyle, ...(isVolHovered && buttonHoverStyle) }}
                size="lg"
                onMouseEnter={() => setIsVolHovered(true)}
                onMouseLeave={() => setIsVolHovered(false)}
                >
                <Nav.Link href="/volunteer">VOLUNTEER</Nav.Link>
            </Button>
        </div>
    );
}

export default Contribute;
import React from 'react';
import { Container, Image } from 'react-bootstrap';
import SocialButtonGroup from './SocialButtonGroup';


const Footer:React.FC = () => { 
    return (
        <Container className="footer">
            <SocialButtonGroup showLabel={true}/>
            <br/>
            <Image src="REYES_BANNER.jpg" width={200} height={64}/>
            <br/>
        </Container>
    );
}

export default Footer;
import { Navigate, Route, Routes } from 'react-router-dom';
import './App.css';
import NavigationBar from './components/Navigation';
import HomePage from './pages/Home';
import AboutPage from './pages/About';
import IssuesPage from './pages/Issues';
import VolunteerPage from './pages/Volunteer';
import ContactPage from './pages/Contact';


function App() {
  return (
    <div className="App">
      <NavigationBar />
      <Routes>
        <Route path="/home" element={<HomePage />}/>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/about" element={<AboutPage />} />
        <Route path="/issues" element={<IssuesPage />} />
        <Route path="/volunteer" element={<VolunteerPage />} />
        <Route path="/contact" element={<ContactPage />} />
      </Routes>
    </div>
  );
}

export default App;

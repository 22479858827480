import React, { useState } from 'react';
import { Button, ButtonProps } from 'react-bootstrap';

interface DefaultButtonProps extends ButtonProps {
    buttonLabel: string;
    isSubmitting?: boolean;
}

const DefaultButton: React.FC<DefaultButtonProps> = ({ buttonLabel, isSubmitting, ...rest }) => {
    const [isHovered, setIsHovered] = useState(false);

    const buttonStyle = {
        backgroundColor: '#cc3333',
        fontFamily: 'Schibsted Grotesk',
        fontWeight: 900,
        borderRadius: 0,
        borderColor: '#cc3333',
        letterSpacing: '2px',
        transition: 'background-color 0.3s ease', // Smooth transition on hover
    };

    const buttonHoverStyle = {
        borderColor: '#24337a',
        backgroundColor: '#24337a', // Change background color on hover
    };

    return (
        <Button
            {...rest}
            className="mb-2"
            style={{ ...buttonStyle, ...(isHovered && buttonHoverStyle) }}
            size="sm"
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            onMouseDown={() => setIsHovered(true)}
            onMouseUp={() => setIsHovered(false)}
            disabled={isSubmitting}
        >
            {isSubmitting ? (
                <>
                    <span
                        className="spinner-border spinner-border-sm"
                        role="status"
                        aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Submitting...</span>
                </>
            ) : (
                buttonLabel
            )}
        </Button>
    );
};

export default DefaultButton;

import { Image } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import SocialButtonGroup from './SocialButtonGroup';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';

  const NavigationBar = () => {
    const [expanded, setExpanded] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();

    const handleSelect = (path:any) => {
      setTimeout(() => {
      setExpanded(false);  
      navigate(path);
      }, 1000); // Delay time in milliseconds
    };

    return (
      <Navbar expand="lg" className="bg-body-tertiary" sticky="top">
        <Container fluid>
          <Navbar.Brand href="/home" className="d-flex justify-content-center align-items-center">
            <Image src="REYES_BANNER.jpg" width={200} height={64} />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto schibsted-grotesk-400">
              <Nav.Link href="/home" className={`nav-link ${location.pathname === '/home' ? 'active' : ''}`}>
                Home
              </Nav.Link>
              <Nav.Link href="/about" className={`nav-link ${location.pathname === '/about' ? 'active' : ''}`}>
                About
              </Nav.Link>
              <Nav.Link href="/issues" className={`nav-link ${location.pathname === '/issues' ? 'active' : ''}`}>
                Issues
              </Nav.Link>
              <Nav.Link href="/volunteer" className={`nav-link ${location.pathname === '/volunteer' ? 'active' : ''}`}>
                Volunteer
              </Nav.Link>
              <Nav.Link href="/contact" className={`nav-link ${location.pathname === '/contact' ? 'active' : ''}`}>
                Contact
              </Nav.Link>
            </Nav>
            <Nav className="ml-auto">
              <SocialButtonGroup showLabel={false} />
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    );
  }

  export default NavigationBar;
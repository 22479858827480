import { Container } from "react-bootstrap";
import HomeHero from "../components/hero/Home";
import Footer from "../components/Footer";
import Contribute from "../components/Contribute";


const HomePage = () => {
    return (
        <Container fluid>
            <HomeHero />
            <br/>
            <Contribute />
            <br/>
            <Footer />
        </Container>
    );
}

export default HomePage;
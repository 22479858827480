import { Card, Container, Form } from "react-bootstrap";
import Footer from "../components/Footer";
import DefaultButton from "../components/DefaultButton";
import { useState } from "react";

interface IVolunteerFormData {
    name: string;
    email: string;
    phone: string;
    activities: string[];
}

const VolunteerPage = () => {
    const [loading, setLoading] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [error, setError] = useState(false);
    const [formData, setFormData] = useState<IVolunteerFormData>({
        name: "",
        email: "",
        phone: "",
        activities: []
    });

    // Event handler for form submission
    const handleSubmit = async (event:any) => {
        event.preventDefault(); // Prevent default form submission behavior
        setLoading(true);

        try {
            // Make a POST request to the backend API endpoint api.campy.network https://api.campy.network/service/volunteer
            const response = await fetch("https://api.campy.network/service/volunteer", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                // If the request is successful, do something (e.g., show a success message)
                setCompleted(true);
                console.log("Form submitted successfully!");
            } else {
                // If the request fails, handle the error (e.g., show an error message)
                console.error("Failed to submit form:", response.statusText);
                setError(true);
            }
        } catch (error) {
            console.error("An error occurred:", error);
            setError(true);
        } finally {
            setLoading(false);
        }
    };

    // Event handler to update form data when input fields change
    const handleInputChange = (event:any) => {
        const { name, value, type, checked } = event.target;

        if (type === "checkbox") {
            // If the input is a checkbox, handle the checked state
            if (checked) {
                // Add the activity to the list if checked
                setFormData(prevState => ({
                    ...prevState,
                    activities: [...prevState.activities, value]
                }));
            } else {
                // Remove the activity from the list if unchecked
                setFormData(prevState => ({
                    ...prevState,
                    activities: prevState.activities.filter(activity => activity !== value)
                }));
            }
        } else {
            // If it's not a checkbox, update the form data as usual
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    return (
        <Container fluid>
            <Card>
                <Card.Header className="card-header-with-image">
                    <div className="header-content">
                        <h1><span className='header-lead schibsted-grotesk-900'>Join The Campaign</span></h1>
                    </div>
                </Card.Header>
                <Card.Body>
                    <Card.Text><span className="volunteer-tagline">We appreciate your willingness to volunteer. We are depending on individuals such as yourselves to take action and change things.</span></Card.Text>
                    {completed ? (
                        <Container className="form-success">
                            <hr/>
                            <h3>We appreciate your willingness to volunteer! You will hear from a member of our team soon.</h3>
                            <h2>Team Reyes</h2>
                        </Container>
                    ) : (
                        <Form className="form-group" onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="formUserName">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" name="name" placeholder="Enter Name" onChange={handleInputChange} required />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" name="email" placeholder="Enter Email" onChange={handleInputChange} required />
                                <Form.Text className="text-muted">
                                    We'll never share your email with anyone else.
                                </Form.Text>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formMobileNumber">
                                <Form.Label>Mobile Phone Number</Form.Label>
                                <Form.Control type="text" name="phone" placeholder="Phone|Mobile Number" onChange={handleInputChange} required />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Form.Label>Activities</Form.Label>
                                <Form.Check type="checkbox" name="activities" value="door" label="Knocking Doors" onChange={handleInputChange} />
                                <Form.Check type="checkbox" name="activities" value="phone" label="Making Phone Calls" onChange={handleInputChange} />
                                <Form.Check type="checkbox" name="activities" value="events" label="Hosting Events" onChange={handleInputChange} />
                            </Form.Group>
                            <DefaultButton buttonLabel="Submit" type="submit" isSubmitting={loading} />
                            {error && <p className="text-danger mt-2">Failed to submit the form. Please try again later.</p>}
                        </Form>
                    )}
                </Card.Body>
            </Card>
            <br />
            <Footer />
        </Container>
    );
}

export default VolunteerPage;
import { Card, Container, Form} from "react-bootstrap";
import Footer from "../components/Footer";
import DefaultButton from "../components/DefaultButton";
import { useState } from "react";

const ContactPage = () => {
    const [loading, setLoading] = useState(false);
    const [completed, setCompleted] = useState(false);

    // State variables to store form data
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: ""
    });

    // Event handler for form submission
    const handleSubmit = async (event:any) => {
        event.preventDefault(); // Prevent default form submission behavior
        setLoading(true);

        try {
            // Make a POST request to the backend API endpoint https://api.campy.network/service/contact
            const response = await fetch("https://api.campy.network/service/contact", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                console.log(response);
                // If the request is successful, do something (e.g., show a success message)
                setCompleted(true);
                console.log("Form submitted successfully!");
            } else {
                // If the request fails, handle the error (e.g., show an error message)
                console.error("Failed to submit form:", response.statusText);
            }
        } catch (error) {
            console.error("An error occurred:", error);
        }  finally {
            setLoading(false);
        }
    };

    // Event handler to update form data when input fields change
    const handleInputChange = (event:any) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    return (
        <Container fluid>
            <Card>
                <Card.Header className="card-header-with-image">
                <div className="header-content">
                    <h1><span className='header-lead schibsted-grotesk-900'>Send Us A Message</span></h1>
                </div>
                </Card.Header>
                <Card.Body>
                <Card.Text><span className="contact-tagline">Please Message Us Someone will get in touch with you soon;<br />we are pleased to assist.</span></Card.Text>
                    {completed ? (
                        <Container className="form-success">
                            <hr/>
                            <h3>We appreciate your message. You will hear from a member of our team soon.</h3>
                            <h2>Team Reyes</h2>
                        </Container>
                    ) : (
                        <Form className="form-group" onSubmit={handleSubmit}>
                            <Form.Group className="mb-3" controlId="formUserName">
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" name="name" placeholder="Enter Name" onChange={handleInputChange} required />
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicEmail">
                                <Form.Label>Email address</Form.Label>
                                <Form.Control type="email" name="email" placeholder="Enter Email" onChange={handleInputChange} required />
                                <Form.Text className="text-muted">
                                    We'll never share your email with anyone else.
                                </Form.Text>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formMobileNumber">
                                <Form.Label>Message</Form.Label>
                                <Form.Control as="textarea" rows={3} name="message" placeholder="Your Message" onChange={handleInputChange} required />
                            </Form.Group>
                            <DefaultButton  buttonLabel="Send" type="submit" isSubmitting={loading}  />
                        </Form>
                    )}
                </Card.Body>
            </Card>
            <br/>
            <Footer />
        </Container>
    );
}

export default ContactPage;